export enum Product {
  gohub = 'gohub',
  godashboard = 'godashboard',
  goplan = 'goplan',
}

export const isProduct = (product: any): product is Product => Object.values(Product).includes(product);

export type Update = {
  id: number;
  name: string;
  product: Product;
  publication_date: string;
};

export type UpdateList = {
  all_updates: Update[];
  not_watched_updates: Update[];
};

export type HasUpdatesResponse = {
  has_updates: boolean;
};
