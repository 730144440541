import { BellOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import styled from 'styled-components';

import { staticColors } from 'src/styles/theme';

export const UpdatesButton = styled(Button)<{ isAlert?: boolean }>`
  width: 40px;
  height: 40px;
  margin: auto 0;
  padding: 8px;
  display: flex;
  background-color: ${({ isAlert }) => (isAlert ? staticColors.red : 'transparent')};

  :hover {
    background-color: ${({ isAlert }) => (isAlert ? staticColors.red : 'transparent')};
    opacity: ${({ isAlert }) => (isAlert ? 0.9 : 1)};
  }

  :focus {
    background-color: ${({ isAlert }) => (isAlert ? staticColors.red : 'transparent')};
  }
  
  @media (max-width: 768px) {
    width: 34px;
    height: 34px;
    padding: 6px;
    border-radius: 8px;
  }
}
`;

export const BellIcon = styled(BellOutlined)`
  margin: auto;
  font-size: 20px;
  color: ${staticColors.white};

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;
