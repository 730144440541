import { UPDATES_SERVICE_URL } from '../../../constants/api';
import { PRODUCT_URL_KEY, Theme, THEME_URL_KEY, TOKEN_URL_KEY } from '../../../constants/common';
import { Product } from '../../../service/UpdatesService.types';

const getFrameSource = (isDarkMode: boolean, product: Product, accessToken: string, latestUpdateId?: number | null) => {
  const updatesFrameParams = new URLSearchParams();
  updatesFrameParams.set(THEME_URL_KEY, isDarkMode ? Theme.dark : Theme.light);
  updatesFrameParams.set(PRODUCT_URL_KEY, product);
  updatesFrameParams.set(TOKEN_URL_KEY, accessToken);

  const updatesFrameParamsString = updatesFrameParams.toString();

  return `${UPDATES_SERVICE_URL}${latestUpdateId || ''}?${updatesFrameParamsString}`;
};

export default getFrameSource;
