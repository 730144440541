import { useEffect, useState, VFC } from 'react';
import { createPortal } from 'react-dom';

import { showErrorMessage } from 'src/utils';

import { Frame } from './UpdatesFrame.styled';
import { UpdatesFrameProps } from './UpdatesFrame.types';

const UpdatesFrame: VFC<UpdatesFrameProps> = ({ src, close }) => {
  const [isFetching, setIsFetching] = useState(false);

  const [portalRoot] = useState(() => document.createElement('div'));

  useEffect(() => {
    document.body.appendChild(portalRoot);

    return () => portalRoot.remove();
  }, [portalRoot]);

  useEffect(() => {
    setIsFetching(true);
    fetch(src, { mode: 'no-cors' })
      .catch(() => {
        showErrorMessage('Failed to fetch at updates service.');
        close?.();
      })
      .finally(() => setIsFetching(false));
  }, [src, close]);

  if (isFetching) {
    return null;
  }

  return createPortal(<Frame title="Updates" src={src} />, portalRoot);
};

export default UpdatesFrame;
