import styled from 'styled-components';

import { LogoIcon } from 'src/assets/icons';
import { staticColors } from 'src/styles/theme';

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const LogoText = styled.span`
  line-height: 16px;
  font-family: PartnerUltraCondensed;
  color: ${staticColors.white};
  font-size: 44px;
  margin-left: 5px;
  margin-top: 5px;

  @media (max-width: 768px) {
    font-size: 40px;
  }
`;

export const LogotypeIcon = styled(LogoIcon)`
  height: 55px;
  width: 55px;

  @media (max-width: 768px) {
    height: 45px;
    width: 45px;
  }
`;
