import { AxiosResponse } from 'axios';

import { PRODUCT_NAME } from 'src/constants';

import agent from './agent';
import { HasUpdatesResponse, UpdateList } from './UpdatesService.types';

export const getUpdatesList = (): Promise<AxiosResponse<UpdateList>> => {
  return agent.get(`/updates/${PRODUCT_NAME}`);
};

export const getHasUpdates = (): Promise<AxiosResponse<HasUpdatesResponse>> => {
  return agent.get(`/updates/${PRODUCT_NAME}/has_updates`);
};
