export const GOHUB_API_URL =
  (window as any)._env_.REACT_APP_GOHUB_API_URL || process.env.REACT_APP_GOHUB_API_URL || 'http://10.10.7.133:2002/';

export const BACKEND_API_URL =
  (window as any)._env_.REACT_APP_BACKEND_API_URL ||
  process.env.REACT_APP_BACKEND_API_URL ||
  'http://10.10.7.133:8080/';

export const GOHUB_REDIRECT_URL =
  (window as any)._env_.REACT_APP_GOHUB_REDIRECT_URL ||
  process.env.REACT_APP_GOHUB_REDIRECT_URL ||
  'http://10.10.7.133:2001/';

export const GOREPORT_URL = (window as any)._env_.REACT_APP_GOREPORT_URL || process.env.REACT_APP_GOREPORT_URL;

export const GOPERFORM_URL = (window as any)._env_.REACT_APP_GOPERFORM_URL || process.env.REACT_APP_GOPERFORM_URL;
