import styled from 'styled-components';

export const Frame = styled.iframe`
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
  border: none;
  z-index: 10;
`;
