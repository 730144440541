import { useCallback, useState } from 'react';

import getFrameSource from '../../components/Updates/utils/getFrameSource';
import { isProduct, Product } from '../../service/UpdatesService.types';

interface Params {
  isDarkMode: boolean;
  product: Product | string;
  accessToken: string;
}

export const useUpdateFrame = ({ isDarkMode, product, accessToken }: Params) => {
  if (!isProduct(product)) {
    throw new Error('Unexpected product name');
  }

  const [showUpdates, setShowUpdates] = useState(false);
  const [latestUpdateId, setLatestUpdateId] = useState<number | null>(null);

  const closeUpdatesFrame = useCallback(() => setShowUpdates(false), []);

  const openUpdatesFrame = useCallback(() => setShowUpdates(true), []);

  const updatesFrameSrc = getFrameSource(isDarkMode, product, accessToken, latestUpdateId);

  return { showUpdates, openUpdatesFrame, closeUpdatesFrame, updatesFrameSrc, setUpdateId: setLatestUpdateId };
};
