import { FC } from 'react';

import { LogoContainer, LogoText, LogotypeIcon } from './Logo.styled';

const Logo: FC = () => (
  <LogoContainer>
    <LogotypeIcon />
    <LogoText>dashboard</LogoText>
  </LogoContainer>
);

export default Logo;
