import { useEffect, useState, VFC } from 'react';

import { getHasUpdates, getUpdatesList } from '../../service/UpdatesService';

import { BellIcon, UpdatesButton } from './Updates.styled';
import { UpdatesProps } from './Updates.types';

enum UpdatesFrameMessages {
  close = 'close',
  allWatched = 'allWatched',
}

const Updates: VFC<UpdatesProps> = ({ openUpdatesFrame, closeUpdatesFrame, setUpdatesId }) => {
  const [hasUnreadUpdates, setHasUnreadUpdates] = useState(false);

  useEffect(() => {
    getUpdatesList().then(({ data }) => {
      if (data.not_watched_updates.length) {
        const sortedUpdates = data.not_watched_updates.sort(
          (first, second) => new Date(second.publication_date).getTime() - new Date(first.publication_date).getTime()
        );

        setUpdatesId(sortedUpdates?.[0]?.id);
        setHasUnreadUpdates(true);
        openUpdatesFrame();
      }
    });
  }, [openUpdatesFrame, setUpdatesId]);

  useEffect(() => {
    const handleMessage = async (event: MessageEvent) => {
      switch (event.data) {
        case UpdatesFrameMessages.close:
          closeUpdatesFrame();
          break;
        case UpdatesFrameMessages.allWatched:
          const {
            data: { has_updates },
          } = await getHasUpdates();

          if (!has_updates) {
            setHasUnreadUpdates(false);
          }
          break;
      }
    };

    window.addEventListener('message', handleMessage);

    return () => window.removeEventListener('message', handleMessage);
  }, [closeUpdatesFrame]);

  const handleUpdatesClick = () => {
    setUpdatesId(null);
    openUpdatesFrame();
  };

  return (
    <>
      <UpdatesButton type="text" isAlert={hasUnreadUpdates} onClick={handleUpdatesClick}>
        <BellIcon />
      </UpdatesButton>
    </>
  );
};

export default Updates;
