import { FC, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { DarkModeIcon, LightModeIcon } from 'src/assets/icons';
import { Applications, Burger, Language, Logo, NavMobile, UserInfo } from 'src/components';
import { PATHS, PRODUCT_NAME } from 'src/constants';
import { ThemeContext } from 'src/context/themeContext';
import { Updates, UpdatesFrame } from 'src/GoUpdates';
import { useUpdateFrame } from 'src/GoUpdates/utils/hooks/useUpdateFrame';
import { getProducts, getUserInfo, ICard, IPersonInfo } from 'src/services/AuthService';
import { getToken, showErrorMessage } from 'src/utils';

import {
  HeaderApp,
  HeaderCol,
  HeaderDataDesktop,
  HeaderRight,
  MobileUpdatesButtonWrapper,
  SwitchTheme,
  UpdatesButtonWrapper,
} from './Header.styled';

const Header: FC = () => {
  const { isDarkMode, toggleTheme } = useContext(ThemeContext);

  const [person, setPerson] = useState<IPersonInfo | null>(null);
  const [products, setProducts] = useState<ICard[] | null>(null);
  const [isShowNav, setIsShowNav] = useState<boolean>(false);

  const { showUpdates, openUpdatesFrame, closeUpdatesFrame, updatesFrameSrc, setUpdateId } = useUpdateFrame({
    isDarkMode,
    product: PRODUCT_NAME,
    accessToken: getToken() || '',
  });

  useEffect(() => {
    const getUserData = async () => {
      try {
        const user = await getUserInfo();
        setPerson(user.data);

        const products = await getProducts();
        setProducts(products.data);
      } catch (error: any) {
        showErrorMessage(error?.message);
      }
    };

    getUserData();
  }, []);

  const onToggleNav = () => {
    setIsShowNav((prevShow) => !prevShow);
  };

  return (
    <HeaderApp>
      <HeaderCol>
        <Link to={PATHS.root}>
          <Logo />
        </Link>

        <HeaderRight>
          <SwitchTheme
            checkedChildren={<DarkModeIcon />}
            unCheckedChildren={<LightModeIcon />}
            defaultChecked={isDarkMode}
            onChange={toggleTheme}
          />
          <MobileUpdatesButtonWrapper>
            <Updates
              openUpdatesFrame={openUpdatesFrame}
              closeUpdatesFrame={closeUpdatesFrame}
              setUpdatesId={setUpdateId}
            />
          </MobileUpdatesButtonWrapper>
          <Burger onToggleNav={onToggleNav} />
        </HeaderRight>
      </HeaderCol>

      <HeaderDataDesktop>
        <Applications products={products} />
        <UpdatesButtonWrapper>
          <Updates
            openUpdatesFrame={openUpdatesFrame}
            closeUpdatesFrame={closeUpdatesFrame}
            setUpdatesId={setUpdateId}
          />
        </UpdatesButtonWrapper>
        <Language />
        <UserInfo person={person} />
      </HeaderDataDesktop>

      <NavMobile isVisible={isShowNav} onClose={onToggleNav} person={person} products={products} />

      {showUpdates && <UpdatesFrame src={updatesFrameSrc} close={closeUpdatesFrame} />}
    </HeaderApp>
  );
};

export default Header;
