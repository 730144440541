import { DownOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { cleanPageMapInAllPages } from 'src/store/pages';
import { getLocale } from 'src/utils';

import { DropdownLang, MenuItem } from './Language.styled';

const Language: FC = () => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const language = getLocale();

  const langLabel = language === 'ru' || language === 'ru-RU' ? 'RU' : 'EN';

  const onClickHandler = (data: { key: string }) => {
    i18n.changeLanguage(data.key);
    dispatch(cleanPageMapInAllPages());
  };

  const menu = (
    <Menu onClick={onClickHandler}>
      <MenuItem key="ru">RU</MenuItem>
      <MenuItem key="en">EN</MenuItem>
    </Menu>
  );

  return (
    <DropdownLang overlay={menu} trigger={['click']}>
      <div>
        {langLabel} <DownOutlined />
      </div>
    </DropdownLang>
  );
};

export default Language;
