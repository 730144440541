import { Layout, Switch } from 'antd';
import styled from 'styled-components';

import { staticColors } from 'src/styles/theme';

export const HeaderApp = styled(Layout.Header)`
  padding: 0 42px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 72px;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background-color: ${staticColors.primary};

  @media (max-width: 768px) {
    width: 100%;
    padding: 0 12px;
  }
`;

export const HeaderCol = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    width: 100%;
    justify-content: space-between;
  }
`;

export const HeaderRight = styled.div`
  display: flex;
  flex-flow: row nowrap;
`;

export const UpdatesButtonWrapper = styled.div`
  margin-right: 24px;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const MobileUpdatesButtonWrapper = styled.div`
  display: none;

  @media (max-width: 768px) {
    margin-left: 10px;
    margin-right: 0;
    display: block;
  }
`;

export const HeaderDataDesktop = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const SwitchTheme = styled(Switch)`
  margin-left: 14px;

  .ant-switch {
    &-inner {
      display: flex !important;
    }
    &-handle::before {
      background-color: #4e8cae !important;
    }
  }

  &.ant-switch {
    &-checked {
      background-color: rgba(0, 0, 0, 0.25) !important;
    }
  }
`;
